import {
	Box,
	Flex,
	Link,
	SimpleGrid,
	Text,
	VStack,
	useMediaQuery,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { graphql, navigate } from 'gatsby';

import BlogHeroIntro from '../components/blog-hero-intro';
import CategoryTag from '../components/CategoryTag';
import HeroContainer from '../components/hero-container';
import Layout from '../components/Base/Layout';
import Pagination from '../components/Shop/Pagination';
import { SEO } from '../components/Base/Seo';
import { fetchAllBlogCategories } from '../queries/fetchAllBlogCategories';

const CategoryTemplate = ({ data, location }: any) => {
	const posts = data.allWpPost.nodes;
	const allCategories = fetchAllBlogCategories();
	const categoryPosts = data.wpCategory.slug;
	const categoryTitle = data.wpCategory.name;

	const params = new URLSearchParams(location.search);
	const pageParam = params.get('page');

	const allPostsPerPage = 12;
	const [postsPerPage, setPostsPerPage] = useState(allPostsPerPage);
	const [currentPage, setCurrentPage] = useState(Number(pageParam) || 1);
	const [input, setInput] = useState(Number(pageParam) || 1);

	const numberOfPages = React.useMemo(() => {
		return Math.ceil(posts.length / postsPerPage);
	}, [posts]);

	React.useEffect(() => {
		if (pageParam && !Number(pageParam)) {
			navigate(`/category/${categoryPosts}`);
		}
		if (Number(pageParam) > numberOfPages) {
			setCurrentPage(numberOfPages);
			navigate(`/blog/${categoryPosts}?page=${numberOfPages}`);
			setInput(numberOfPages);
		}
		if (Number(pageParam) < 1) {
			setCurrentPage(1);
			navigate(`/blog/${categoryPosts}`);
			setInput(1);
		}
	}, [pageParam, numberOfPages]);

	const visiblePosts = React.useMemo(() => {
		const indexOfLastPosts = currentPage * postsPerPage;
		const indexOfFirstPosts = indexOfLastPosts - postsPerPage;

		return posts.slice(indexOfFirstPosts, indexOfLastPosts);
	}, [posts, currentPage]);

	const prevPageHandler = () => {
		if (currentPage !== 1) {
			document.body.scrollTop = document.documentElement.scrollTop = 0;
			setCurrentPage(currentPage - 1);
			setInput(currentPage - 1);
			navigate(`/blog/${categoryPosts}?page=${currentPage - 1}`);
		}
	};

	const nextPageHandler = () => {
		if (currentPage !== numberOfPages) {
			document.body.scrollTop = document.documentElement.scrollTop = 0;
			setCurrentPage(currentPage + 1);
			setInput(currentPage + 1);
			navigate(`/blog/${categoryPosts}?page=${currentPage + 1}`);
		}
	};

	const [isLargerScreen] = useMediaQuery('(min-width: 768px)');

	const goToPage = (number: number) => {
		setCurrentPage(number);
		window.scrollTo(0, 0);
	};

	return (
		<Layout>
			<HeroContainer
				heroImage='/dm-blog-hero.webp'
				heroSubTitle='Blog Category'
				heroTitle={categoryTitle}
				sectionDescription=''
				arrowImage='/remixiconslinesystemarrowdownsline1.svg'
				heroBackgroundPadding='var(--padding-10xl) 0rem'
			/>
			<VStack
				pt={0}
				pb={12}
				px={{ base: 5, md: 16 }}
				spacing={10}
				alignItems='flex-start'
				maxW='100%'>
				<Box>
					<Box
						backgroundColor='#b97230'
						width='1.5re'
						height='0.13rem'
					/>
					<Text
						as='h2'
						fontSize={'4xl'}>
						Other Categories
					</Text>
				</Box>
				{isLargerScreen ? (
					<Flex
						flexWrap={'wrap'}
						gap={2.5}>
						{allCategories
							// @ts-ignore
							.filter((cat) => cat.slug !== categoryPosts)
							// @ts-ignore
							.map((item, index) => (
								<Link
									style={{ textDecoration: 'none' }}
									key={index}
									href={`/blog/${item.slug}/`}>
									<CategoryTag category={item.name} />
								</Link>
							))}
					</Flex>
				) : (
					<Flex flexWrap={'wrap'}>
						{allCategories
							// @ts-ignore
							.filter((cat) => cat.slug !== categoryPosts)
							// @ts-ignore
							.map((item, index) => (
								<Link
									style={{ textDecoration: 'none' }}
									mr={3}
									mb={3}
									key={index}
									href={`/blog/${item.slug}`}>
									<CategoryTag category={item.name} />
								</Link>
							))}
					</Flex>
				)}
			</VStack>
			<Box id='anchorlink'>
				<SimpleGrid
					columns={{ base: 1, md: 2, lg: 3 }}
					gap={{ base: '12', lg: '9' }}>
					{visiblePosts.map(
						(post: {
							featuredImage: any;
							categories: any;
							excerpt: string;
							author: any;
							date: string | undefined;
							id: React.Key | null | undefined;
							slug: string | undefined;
							title:
							| string
							| number
							| boolean
							| React.ReactElement<
								any,
								string | React.JSXElementConstructor<any>
							>
							| React.ReactFragment
							| React.ReactPortal
							| null
							| undefined;
						}) => (
							<BlogHeroIntro
								link={'/blog/' + post.slug}
								blogImgThumb={post.featuredImage?.node?.sourceUrl}
								category={post.categories.nodes
									?.slice(0, 1)
									.map((item: any) => item.name)}
								// @ts-ignore
								postTitle={post.title}
								excerpt={post.excerpt}
								author={post.author?.node?.name}
								authorSlug={post.author?.node?.slug}
								date={post.date}
							/>
						)
					)}
				</SimpleGrid>
			</Box>
			<Pagination
				numberOfPages={numberOfPages}
				currentPage={currentPage}
				prevPageHandler={prevPageHandler}
				nextPageHandler={nextPageHandler}
				goToPage={goToPage}
				baseUrl={`blog/${categoryPosts}`}
			/>
		</Layout>
	);
};

export default CategoryTemplate;

export const Head = ({ data }: any) => (
	<SEO
		title={data.wpCategory.seo.title}
		description={data.wpCategory.seo.metaDesc}
	/>
);

export const query = graphql`
	query ($categoryId: String!) {
		wpCategory(id: { eq: $categoryId }) {
			name
			slug
			seo {
				title
				metaDesc
			}
		}
		allWpCategory {
			nodes {
				name
				slug
			}
		}
		allWpPost(
			filter: {
				categories: { nodes: { elemMatch: { id: { eq: $categoryId } } } }
			}
		) {
			nodes {
				id
				link
				slug
				excerpt
				uri
				content
				title
				excerpt
				date(formatString: "DD MMM YYYY")
				author {
					node {
						name
						slug
					}
				}
				categories {
					nodes {
						name
						slug
					}
				}
				featuredImage {
					node {
						sourceUrl
						uri
					}
				}
			}
		}
	}
`;
